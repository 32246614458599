.navbarContainer {
    width: 100%;
    height: 115px;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
}
.logo {
    margin: 20px 40px;
 }
.navbar-list {
    display: flex;
    padding: 40px 20px 30px 30px;
    cursor: pointer;
}

a:not([href]):not([class]) {
    text-decoration: none;
    font-size: 17px;
    color: #091727 !important;
    font-weight: 900 !important;
    margin: 0 30px;
}

.active {
    text-decoration: none;
    font-size: 17px;
    background: #406b9c !important;
    color: #fafafa;
    line-height: 20px;
    padding: 5px 10px;
    border-radius: 20px;
}


.nacstCard {
    max-width: 420px;
    padding: 20px 16px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: transform .2s;
    font-weight: 800;
}
.nacstCard:hover{
    transform: scale(1.05); 
}

.nacstCard h4{
    font-weight: 900 !important;
}

.coursesCard {
    justify-content: center;
    text-align: left;
    min-width: 600px;
    min-height: 150px;
    padding: 10px;
    margin: 0;
}

.CoursesCardContent h4 {
    font-size: 18px;
    text-align: left;
    margin: 10px;
    font-weight: 600;
}

.CoursesCardContent p {
    font-size: 13px;
    text-align: left;
    margin: 10px;
}
.cardContent a {
    margin: 0 !important;
}