@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
}

.app {
  width: 100%;
}