html,body{
    overflow-x: hidden !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
}
* {
    box-sizing: border-box;
  }
  
body{
    outline: none !important;
}

.mainSection {
    width: 100%;
    display: inline-block;
}

#main {
    margin-top: 20px;
}
p {
    font-size: 15px;
    font-weight: 500;
}
iframe {
    margin-top: 20px;
}
.pdtb {
    padding: 45px 0;
    text-align: center;
}

.center {
    text-align: center;
    text-align: -webkit-center;
}

#main .headingContent {
    font-size: 16px;
    text-align: left;
    padding: 0 0 12px 26px;
}

#main .heading {
    color: #00275B;
    font-size: 43px;
    font-weight: 900 !important;
    letter-spacing: 0px;
    word-spacing: 0px;
    padding: 0 0 12px 26px;
    text-align: left;
}
.heading {
    color: #00275B;
    text-align: center;
    font-size: 32px;
    font-weight: 900 !important;
}
ul{
    margin: 0;
}
li {
    text-align: left;
    font-size: 15px;
    font-weight: 900 !important;
}

.form {
    max-width: 430px;
    background: #fff;
    border: 0.5px solid rgba(202, 202, 202, 0.2);
    border-radius: 10px;
    padding: 20px 10px;
    box-shadow: 0 0 10px #ddd;
    margin: 20px 30px;
}

.inputWrapper{
    width: 100%;
    padding: 15px 10px;
}
.form h3 {
    margin: 10px 0 30px 0;
    font-size: 25px;
    color: #333;
    font-weight: 900 !important;
}
input, select {
    width: 330px;
    background: #eee;
    height: 45px;
    border: 1px solid #dddd;
    padding: 10px 20px;
    border-radius: 7px;
    font-size: 15px !important;
    color: #555;
    outline: none !important;
}

/* button {
    border: 1px solid #F48120;
    background-color: #F48120;
    border-radius: 15px;
    padding: 8px 30px ;
    color: #fff;
    font-size: 17px;
    font-weight: 900;
} */

.send {
    border: 1px solid #F48120 ;
    background-color: #F48120;
    border-radius: 5px !important;
    padding: 8px 30px !important;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}

.headingContent {
    color: #333;
    font-size: 16px;
    padding: 5px 0 7px 26px;
    text-align: center;
}

.borderBlue {
    width: 150px;
    background: #178BC4;
    height: 4px;
    margin: 0 auto;
    border-radius: 10px;
}
.feedbackContent {
    padding: 20px 40px;
    text-align: left;
    box-shadow: 0 0 10px #ccc;
    margin: 30px;
    border-radius: 10px;
    background: #fff;
}

.feedbackStudent {
    display: inline-flex;
}
.feedbackStudent p {
    font-size: 15px;
    font-weight: 900 !important;
    color: #444;
}
.feedbackStudent img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.cardContent a {
    color: #333 !important;
    font-size: 16px !important; 
    text-decoration: underline !important;
}

.cardContent h3 {
    font-size: 26px;
    color: #333;
    font-weight: 900 !important;
}
.cardContent {
    width: 100%;
    padding: 25px 50px;
    text-align: left;
}
.cardContent p {
    font-size: 16px;
    margin: 0;
}

.nacst {
    display: inline-flex;
    text-align: left;
    min-width: 500px;
    margin: 10px 0;
}

.bgMain img {
    position: absolute;
    top: 0;
    width: 100%;
}

section#contact img {
    transition: transform .2s;
}
section#contact img:hover {
    transform: scale(1.1); 
}
.bgCourses img {
    width: 100%;
    position: absolute;
    left: 30px;
    top: 0px;
}
.bgCourses {
    width: 100%;
    position: relative;
    background: aliceblue;
}

section#footer {
    width: 100%;
    background: #007FBE;
    color: #fff;
}
a.footerLink {
    color: #fafafa;
    display: inline-block;
    font-size: 17px;
    font-weight: 500 !important;
}
.bgFeedback.row.pdtb img {
    position: relative !important;
    width: 100%;
}
.feedback.row.pdtb {
background-color: aliceblue;
padding: 60px 150px;
margin: 45px -15px;
}
.feedbackHeading{
    font-size: 40px;
    padding: 25px 0 0 0;
    color: #00275B;
}
.mobResults.container-fluid, .container-fluid.mobCourses, .bgMainMob img, .webFooter.row{
    display: none;
}
.container-fluid.webCourses .col-6 {
    box-shadow: 0 0 10px #eee;
}

@media only screen and (max-width: 768px) {
    /* For mobile: */
    .navbarItem, .bgMain img{
        display: none;
    }
    .logo {
        text-align: center !important;
        margin: 20px auto !important;
    }
    .logo img {
        width: 100px;
    }
    .col-6, .col-4{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    #main .heading { 
    font-size: 23px;
    line-height: 10px;
        text-align: center;
        padding: 0;
        letter-spacing: 0;
        word-spacing: 0;
        position: absolute;
        top: -40px;
    }
    
    #main .headingContent {
        font-size: 14px;
        line-height: 15px;
        padding: 10px 0 0 0;
        position: absolute;
        top: -20px;
    }
    .bgMainMob img {
        display: inherit !important;
        position: absolute;
        top: 0px;
        margin: 0 auto;
        text-align: center;
        left: 0;
        width: 100%;
    }
    input, select {
    width: 230px;
    height: 40px;
    padding: 10px 20px;
}
.inputWrapper {
    padding: 15px 0;
}
.form {
    padding: 20px 20px;
    min-width: 300px;
    margin: 215px 15px 0 15px;
}
.form h3 {
    margin: 10px 30px;
    font-size: 20px;
    color: #333;
    font-weight: 900 !important;
}
.heading {
    font-size: 20px;
    font-weight: 900 !important;
}
.headingContent {
    font-size: 15px;
    padding: 5px 10px 15px;
    text-align: left;
}
.nacstCard {
    padding: 20px 16px 0;
    max-width: 260px !important;
}
.nacstCard h4 {
    font-weight: 900 !important;
    font-size: 18px;
}
p {
    font-size: 14px;
}
.container-fluid.mobCourses{
    display: inherit;
}
.container-fluid.mobCourses .coursesCard {
    margin: 15px 0 !important;
    width: 100% !important;
    min-width: inherit !important;
    min-height: inherit !important; 
    padding: 0 !important;
}

.container-fluid.webCourses {
   display: none;
}
.mobResults.container-fluid .feedback.row.pdtb {
    padding: 0;
    display: inline-block;
}
.mobResults.container-fluid {
    display: inherit ;
}
.webResults{
    display: none ;
}
.mobResults.container-fluid .feedbackHeading {
    font-size: 21px;
    padding: 0px;
    text-align: left !important;
}

section#contact img {
    width: 100%;
}
section#contact .col-6 {
    box-shadow: 0 0 10px #bbb;
    margin: 10px 0;
    background: #fff;
    padding: 0;
    text-align: left;
    border-radius: 10px;
}
.cardContent {
    padding: 25px;
}
section#contact {
    padding: 15px;
}
.webFooter.row{
    display: none;
}
.container-fluid.mobCourses .col-6 {
    box-shadow: 0 0 10px #eee;
}
iframe {
    width: 100%;
}

}
button.tickbutton {
    background: none;
    border: none;
    color: #f1813a;
    width: 45px;
    padding: 0;
    height: 50px;
    font-size: 22px;
}